import React from 'react';
import {
    Avatar,
    Box,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../assets/img/logo_white.svg";

const pages = [
    { key: "features", label: "Features", to: "features" },
    { key: "pricing", label: "Pricing", to: "pricing" },
]

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    return (
        <Box position="static" className="navbar navbar-wrapper">
            <Container>
                <Toolbar disableGutters>
                    <Avatar
                        variant="square"
                        sx={{
                            backgroundColor: "transparent",
                            height: "30px",
                            width: "30px",
                        }}>
                        <Logo />
                    </Avatar>
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        sx={{
                            ml: 2,
                            mr: 2,
                            display: { xs: 'none', md: 'flex' }
                        }}
                    >
                        PRECURSOR PRO
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical:   'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical:   'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            keepMounted
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.key + "-menu"} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                    >{page.key}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/*Menu*/}
                    <Box className="nav-menu" sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Link
                                key={page.key}
                                to={page.to}
                                onClick={handleCloseNavMenu}
                                className="nav-item"
                            >
                                {page.label}
                            </Link>
                        ))}
                    </Box>

                    {/*User Icon*/}
                    <Box id="userIcon" sx={{ flexGrow: 0}}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Avatar alt={"Alex Kohut"} src="/static/image/avatar/2.jpg" />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical:   'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical:   'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}

                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </Box>
    );
};

export default Header;
