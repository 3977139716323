import React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import FeatureMatrixBox from "./feature-matrix-box";
import {
    ChatBubbleOutlineOutlined,
    DateRangeOutlined, EmojiEventsOutlined,
    OndemandVideoOutlined, RocketLaunchOutlined, SecurityOutlined,
    WaterfallChartOutlined, WorkspacePremiumOutlined
} from "@mui/icons-material";

const FeatureMatrix = () => {
    return (
        <Container id="feature-matrix" className="light-container">
            <Typography variant="h3" marginBottom={5} className="clean-text" textAlign="center">
                What you'll receive
            </Typography>
            <Grid container spacing={2} alignContent="center" alignItems="flex-start" rowGap={2}>
                <FeatureMatrixBox
                    icon     = {<OndemandVideoOutlined className="icon" />}
                    boxTitle = "Video Training Courses"
                    boxText  = "Stay up to date with the latest trends, anytime, anywhere."
                />

                <FeatureMatrixBox
                    icon     = {<ChatBubbleOutlineOutlined className="icon" />}
                    boxTitle = "Live Collaboration"
                    boxText  = "Keep your skills sharp when you join a community of active traders."
                />

                <FeatureMatrixBox
                    icon     = {<DateRangeOutlined className="icon" />}
                    boxTitle = "Weekly Live Training"
                    boxText  = "Get real time access, and your questions answered during weekly training sessions."
                />

                <FeatureMatrixBox
                    icon     = {<WaterfallChartOutlined className="icon" />}
                    boxTitle = "Understand Market Pulse"
                    boxText  = "Get an in-depth understanding of the different trading conditions."
                />

                <FeatureMatrixBox
                    icon     = {<SecurityOutlined className="icon" />}
                    boxTitle = "Develop Risk Management"
                    boxText  = "Safeguard your investments by taking charge of your portfolio."
                />

                <FeatureMatrixBox
                    icon     = {<WorkspacePremiumOutlined className="icon" />}
                    boxTitle = "One on One Support"
                    boxText  = "Our experienced team of mentors will help to personally guide you."
                />

                <FeatureMatrixBox
                    icon     = {<EmojiEventsOutlined className="icon" />}
                    boxTitle = "Win More"
                    boxText  = "Join competitive rounds, using what you learn, with live help and get rewarded."
                />

                <FeatureMatrixBox
                    icon     = {<RocketLaunchOutlined className="icon" />}
                    boxTitle = "Get Sarted Today"
                    boxText  = "No lengthy sign up processes, start learning right away."
                />
            </Grid>
        </Container>
    );
};

export default FeatureMatrix;
