import React from 'react';
import styled from "styled-components";
import Hero from "./Hero";
import FeatureMatrix from "./feature-matrix/feature-matrix";
import Footer from "./Footer";

const Home = () => {
    return (
        <StyledContainer className="light-container">
            <Hero border="is-slanted" />
            <FeatureMatrix />
            <Footer />
        </StyledContainer>
    );
};

export default Home;

const Container = ({ className, children }) => (
    <div className={className}>
        {children}
    </div>
)

const StyledContainer = styled(Container)`
  height: 100%;
`;