import React from 'react';
import Home from "components/Home";
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";

function App() {
  return (
      <Routes>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="/img/*" />
          <Route path="*" element={<Home />}></Route>
      </Routes>
  );
}

export default App;
