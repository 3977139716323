import React from 'react';
import {Avatar, Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../assets/img/logo.svg";

const MyComponent = () => {
    return (
        <Container maxWidth="xs">
            <Box display="flex" justifyContent="center" mt={4} mb={6}>
                <Avatar variant="square" sx={{backgroundColor: "transparent"}}>
                    <Logo />
                </Avatar>
            </Box>

            <Typography component="h1" variant="h4" align="center" className="clean-text">
                Join Us
            </Typography>

            <Typography align="center" className="clean-text">
                Start by creating your account
            </Typography>

            <Box textAlign="center" mt={2} mb={2} className="accent">
                <Link replace to="/login" align="center">I already have an account</Link>
            </Box>

            <Box component="form" className="form-card">
                <Grid columns={2} display="flex" gap={2}>
                    <Grid item>
                        <TextField
                            className    = "form-input"
                            id           = "firstName"
                            label        = "First Name"
                            autoComplete = "firstName"
                            fullWidth
                            autoFocus
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className    = "form-input"
                            id           = "lastName"
                            label        = "Last Name"
                            autoComplete = "lastName"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <TextField
                    className    = "form-input"
                    id           = "email"
                    label        = "Email"
                    autoComplete = "email"
                    sx={{mt: 3}}
                    fullWidth
                />
                <TextField
                    id           = "password"
                    label        = "Password"
                    type         = "password"
                    autoComplete = "current-password"
                    sx={{mt: 3}}
                    fullWidth
                />
                <TextField
                    id           = "password-confirm"
                    label        = "Confirm Password"
                    type         = "password"
                    autoComplete = "cofirm-password"
                    sx={{mt: 3}}
                    fullWidth
                />
                <Button
                    type="submit"
                    sx={{ mt: 5 }}
                    fullWidth
                    className="button-primary"
                >
                    Register
                </Button>
            </Box>
        </Container>
    );
};

export default MyComponent;
