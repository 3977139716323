import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import {Link} from "react-router-dom";

const Login = () => {
    const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      console.log({
          email: data.get('email'),
          password: data.get('password'),
      });
    }

    return (
        <Container maxWidth="xs">
            <Box display="flex" justifyContent="center" mt={4} mb={6}>
                <Avatar variant="square" sx={{backgroundColor: "transparent"}}>
                    <Logo />
                </Avatar>
            </Box>

            <Typography component="h1" variant="h4" align="center" className="clean-text">
                Welcome Back
            </Typography>

            <Typography align="center" className="clean-text">
                Please sign in to your account
            </Typography>

            <Box textAlign="center" mt={2} mb={2} className="accent">
                <Link replace to="/register" align="center">I do not have an account</Link>
            </Box>

            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}} className="form-card">
                <TextField
                    className    = "form-input"
                    id           = "email"
                    label        = "Email"
                    autoComplete = "email"
                    fullWidth
                    autoFocus
                />
                <TextField
                    id           = "password"
                    label        = "Password"
                    type         = "password"
                    autoComplete = "current-password"
                    sx={{mt: 3}}
                    fullWidth
                />
                <FormControlLabel
                    control = {<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    sx={{pt: 2}}
                />
                <Button
                    type="submit"
                    sx={{ mt: 3 }}
                    fullWidth
                    className="button-primary"
                >
                    Sign In
                </Button>
            </Box>
            <Box pt={4} textAlign="center" className="clean-text">
                <Link to="/login/forgot-password">Forgot Password?</Link>
            </Box>
        </Container>
    );
};

export default Login;
