import React from 'react';
import {Box, Container, Grid} from "@mui/material";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <Container className="footer">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx = {{
                        textAlign: { xs: "center", md: "left" }
                    }}
                >
                    <Box>
                        Precursor Pro &copy; {(new Date().getFullYear())}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs = {12}
                    md = {6}
                    sx = {{
                        textAlign: { xs: "center", md: "right" },
                        marginTop: { xs: 5, md: 0 },
                    }}
                >
                    <Box className="footer-nav">
                        <Link to="/">Home</Link>
                        <Link to="features">Features</Link>
                        <Link to="pricing">Pricing</Link>
                        <Link to="login">Sign in</Link>
                        <Link to="register">Sign up</Link>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
