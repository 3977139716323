import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";

class FeatureMatrixBox extends PureComponent {
    icon = this.props.icon;

    render() {
        return (
            <Grid item xs={6} md={3} textAlign="center">
                {this.icon}
                <div className="box-title clean-text">
                    {this.props.boxTitle}
                </div>
                <div className="box-text">
                    {this.props.boxText}
                </div>
            </Grid>
        );
    }
}

FeatureMatrixBox.propTypes = {
    icon:     PropTypes.element,
    boxTitle: PropTypes.string,
    boxText:  PropTypes.string,
};

export default FeatureMatrixBox;