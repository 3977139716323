import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Header from "components/Header";
import {Box, Container, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";

class Hero extends PureComponent {
    render() {
        return (
            <div className={`hero ${this.props.border}`}>
                <Header />
                <Container>
                    <Grid container spacing={0} textAlign="left" alignItems="center">
                        <Grid item xs={12} md={5}>
                            <Typography className="clean-title" variant="h3"  component="div" align="left">
                                Trade like a Pro.
                            </Typography>
                            <div className="subtitle clean-text">
                                Learn how to identify market shifts before they happen.
                            </div>
                            <Box sx={{mt: 5, mb: 5}}>
                                <a href="/register" className="button cta">
                                    Get Started
                                </a>
                                <Link to="/login" className="button cta">Sign In</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={0} md={1} />
                        <Grid item xs={12} md={6} height={"500px;"}>

                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

Hero.propTypes = {
    border: PropTypes.string
};

export default Hero;
